// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAlldeposits = createAsyncThunk(
  "deposits/getAllData",
  async (view) => {
    let bodyContent = {
      params: {
        query: { type: view },
        options: {
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/accountings/deposit", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      total: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "deposits/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().deposits.params;
    var newparams = {
      ...strparams,
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/accountings/deposits", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const createDeposit = createAsyncThunk(
  "deposits/create",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post("/accountings/deposit", formdata);
    dispatch(addDeposit(response.data?.data));

    return { status: response.status, message: response.message };
  }
);
export const createStaffDeposit = createAsyncThunk(
  "deposits/createStaffDeposit",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post("/accountings/deposit/staffDeposit", formdata);
    dispatch(addDeposit(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateDeposit = createAsyncThunk(
  "deposits/update",
  async ({ data, id }, { dispatch, getState }) => {
    var response = await axios.patch(`/accountings/deposits/${id}`, data);
    dispatch(editDeposit(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteDeposit = createAsyncThunk(
  "deposits/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/accountings/deposit/${id}`);
    await dispatch(getAlldeposits());
    return response.status;
  }
);

export const depositslice = createSlice({
  name: "deposits",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedDeposit: null,
  },
  reducers: {
    addDeposit: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectDeposit: (state, { payload }) => {
      state.selectedDeposit = payload;
    },
    editDeposit: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedDeposit = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlldeposits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAlldeposits.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.total;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getAlldeposits.rejected, (state, action) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createDeposit.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedCustomer = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(createDeposit.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedCustomer = null;
      })
      .addCase(createDeposit.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStaffDeposit.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedStaff = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(createStaffDeposit.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedStaff = null;
      })
      .addCase(createStaffDeposit.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addDeposit, selectDeposit, editDeposit } = depositslice.actions;
export default depositslice.reducer;
