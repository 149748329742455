// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllData = createAsyncThunk(
  "users/getAllData",
  async (_, thunkApi) => {
    try {
      let bodyContent = {
        options: {
          query: { name: "Ahmed" },
          populate: [
            {
              path: "role",
              dir: "roles",
            },
            {
              path: "company",
              dir: "companies",
            },
          ],
        },
      };

      const response = await axios.get("/users", {
        params: bodyContent,
      });

      const docs = response.data?.data?.docs || [];
      return docs;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkApi.rejectWithValue(err.response.data.message);
    }
  }
);

export const getData = createAsyncThunk("users/getData", async (params) => {
  const response = await axios.get("/users", params);
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total,
  };
});

export const getUser = createAsyncThunk("users/getUser", async (id) => {
  const response = await axios.get(`/users/${id}`);
  return response.data.data;
});

export const addUser = createAsyncThunk(
  "users/addUser",
  async (user, { dispatch, getState }) => {
    await axios.post("/users", user);
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return user;
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/users", { id });
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return id;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload;
      })
      .addCase(getAllData.rejected, (state, action) => {
        console.log("ERROR", action.payload);
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default usersSlice.reducer;
