// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllpendingTransactions = createAsyncThunk(
  "closes/getAllData",
  async (view) => {
    let bodyContent = {
      params: {
        query: { type: view },
        options: {
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/accountings/closeEntry", bodyContent);
    const docs = response.data?.data || [];
    return {
      docs,
      total: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const countPendingTransactions = createAsyncThunk(
  "closes/getAllData",
  async (view) => {
    let bodyContent = {
      params: {
        query: { type: view },
      },
    };
    const response = await axios.get(
      "/accountings/closeEntry/count",
      bodyContent
    );
    const docs = response.data || [];
    return {
      docs,
    };
  }
);

export const getData = createAsyncThunk(
  "closes/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().deposits.params;
    var newparams = {
      ...strparams,
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/accountings/closeEntry", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const closeAllPendingTransactions = createAsyncThunk(
  "closes/close",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.get("/accountings/closeEntry/closeAll");
    // dispatch(addDeposit(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const closeSinglePendingTransaction = createAsyncThunk(
  "closes/get",
  async ({ id }, { dispatch, getState }) => {
    var response = await axios.get(`/accountings/closeEntry/close/${id}`);
    // dispatch(editDeposit(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteDeposit = createAsyncThunk(
  "deposits/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/accountings/deposit/${id}`);
    await dispatch(getAllpendingTransactions());
    return response.status;
  }
);

export const closeslice = createSlice({
  name: "closes",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedTransaction: null,
  },
  reducers: {
    addDeposit: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectTransaction: (state, { payload }) => {
      state.selectedTransaction = payload;
    },
    editDeposit: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedTransaction = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllpendingTransactions.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.total;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getAllpendingTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllpendingTransactions.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(closeSinglePendingTransaction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(closeSinglePendingTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(closeSinglePendingTransaction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(closeAllPendingTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(closeAllPendingTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(
        closeAllPendingTransactions.rejected,
        (state, { payload, error }) => {
          state.loading = false;
          state.error = payload;
        }
      );
    // .addCase(getData.fulfilled, (state, action) => {
    //   state.data = action.payload.data;
    //   state.params = action.payload.params;
    //   state.loading = false;
    // });
  },
});

export const { addDeposit, selectTransaction, editDeposit } =
  closeslice.actions;
export default closeslice.reducer;
