// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getAllPromises = createAsyncThunk(
  "promises/getAllData",
  async ({ startDate, endDate }) => {
    let bodyContent = {
      params: {
        options: {
          query: {},
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
          ],
          startDate,
          endDate,
        },
      },
    };
    const response = await axios.get("/customers/promises", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data || [];

    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk("promises/getData", async (params) => {
  const response = await axios.get("/customers/promises", {
    params: {
      options: {
        ...params,
        populate: [
          {
            path: "createdBy",
            dir: "users",
            select: "username",
          },
        ],
      },
    },
  });
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const promiseSlice = createSlice({
  name: "customers",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    selectedCustomer: null,
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      state.error = null;
    },
    addCustomer: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectCustomer: (state, { payload }) => {
      state.selectedCustomer = payload;
    },
    editCustomer: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedCustomer = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllPromises.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPromises.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllPromises.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      });
    // .addCase(createCustomer.pending, (state, { payload }) => {
    //   state.loading = true;
    //   state.error = null;
    // })
  },
});

export const { selectCustomer, searchFilter, isLoading } = promiseSlice.actions;
export default promiseSlice.reducer;
