// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllUtilityTypes = createAsyncThunk(
  "UtilityTypes/getAllData",
  async (_, { getState }) => {
    let bodyContent = {
      params: {
        query: {
          building: getState().CurrentBuilding?.data?._id ?? "",
        },
        options: {
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            }
          ],
        },
      },
    };
    const response = await axios.get("/UtilityTypes", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      totalRows: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "UtilityTypes/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().UtilityTypes.params;
    var newparams = {
      ...strparams,
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/UtilityTypes", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const createUtilityType = createAsyncThunk(
  "UtilityTypes/create",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post("/UtilityTypes", formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(addUtilityType(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateUtilityType = createAsyncThunk(
  "UtilityTypes/update",
  async ({ data, id }, { dispatch, getState }) => {
  
    var response = await axios.patch(`/UtilityTypes/${id}`, data);
    // await dispatch(getData(getState().users.params));
    dispatch(editUtilityType(response.data?.data));
    return { status: response.status, message: response.message };
  }
);
export const cancelUtilityType = createAsyncThunk(
  "UtilityTypes/cancel",
  async (id, { dispatch, getState }) => {
    var response = await axios.patch(`/UtilityTypes/cancel/${id}`);
    // await dispatch(getData(getState().users.params));
    dispatch(getAllUtilityTypes());
    return { status: response.status, message: response.message };
  }
);

export const deleteUtilityType = createAsyncThunk(
  "UtilityTypes/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/UtilityTypes/${id}`);
    await dispatch(getAllUtilityTypes());
    return response.status;
  }
);

export const UtilityTypeslice = createSlice({
  name: "UtilityTypes",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedUtilityType: null,
  },
  reducers: {
    addUtilityType: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectUtilityType: (state, { payload }) => {
      state.selectedUtilityType = payload;
    },
    editUtilityType: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedUtilityType = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllUtilityTypes.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addUtilityType, selectUtilityType, editUtilityType } = UtilityTypeslice.actions;
export default UtilityTypeslice.reducer;
