// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllNewTransactions = createAsyncThunk(
  "closes/getAllData",
  async (view) => {
    let bodyContent = {
      params: {
        query: { type: view },
        options: {
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/accountings/newEntry", bodyContent);
    const docs = response.data?.data || [];
    return {
      docs,
      total: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "closes/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().deposits.params;
    var newparams = {
      ...strparams,
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/accountings/closeEntry", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const recordAllNewTransactions = createAsyncThunk(
  "closes/close",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.get("/accountings/newEntry/recordAll ");
    // dispatch(addDeposit(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const recordSingleTransaction = createAsyncThunk(
  "closes/close",
  async ({ id }, { dispatch, getState }) => {
    var response = await axios.get(`/accountings/newEntry/record/${id}`);
    // dispatch(editDeposit(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteDeposit = createAsyncThunk(
  "deposits/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/accountings/deposit/${id}`);
    await dispatch(getAllNewTransactions());
    return response.status;
  }
);

export const closeslice = createSlice({
  name: "newEntry",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedDeposit: null,
  },
  reducers: {
    addDeposit: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectDeposit: (state, { payload }) => {
      state.selectedDeposit = payload;
    },
    editDeposit: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedDeposit = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllNewTransactions.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.total;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addDeposit, selectDeposit, editDeposit } = closeslice.actions;
export default closeslice.reducer;
