// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllexchanges = createAsyncThunk(
  "exchanges/getAllData",
  async (view) => {
    let bodyContent = {
      params: {
        query: { type: view },
        options: {
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/accountings/exchange", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      total: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "exchanges/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().exchanges.params;
    var newparams = {
      ...strparams,
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/accountings/exchanges", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const exchangeAndDeposit = createAsyncThunk(
  "exchanges/createdep",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post(
      "/accountings/exchange/exchange-and-deposit",
      formdata
    );
    dispatch(addExchange(response.data?.data));

    return { status: response.status, message: response.message };
  }
);
export const exchangeAndWithdraw = createAsyncThunk(
  "exchanges/createwith",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post(
      "/accountings/exchange/exchange-and-withdraw",
      formdata
    );
    dispatch(addExchange(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateExchange = createAsyncThunk(
  "exchanges/update",
  async ({ data, id }, { dispatch, getState }) => {
    var response = await axios.patch(`/accountings/exchanges/${id}`, data);
    dispatch(editExchange(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteExchange = createAsyncThunk(
  "exchanges/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/accountings/exchange/${id}`);
    await dispatch(getAllexchanges());
    return response.status;
  }
);

export const exchangeslice = createSlice({
  name: "exchanges",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedExchange: null,
  },
  reducers: {
    addExchange: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectExchange: (state, { payload }) => {
      state.selectedExchange = payload;
    },
    editExchange: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedExchange = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllexchanges.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllexchanges.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.total;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getAllexchanges.rejected, (state, action) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(exchangeAndDeposit.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedCustomer = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(exchangeAndDeposit.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedCustomer = null;
      })
      .addCase(exchangeAndDeposit.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exchangeAndWithdraw.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedCustomer = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(exchangeAndWithdraw.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedCustomer = null;
      })
      .addCase(exchangeAndWithdraw.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addExchange, selectExchange, editExchange } =
  exchangeslice.actions;
export default exchangeslice.reducer;
