// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getAllCustomers = createAsyncThunk(
  "customers/getAllData",
  async ({ balanced }) => {
    let bodyContent = {
      params: {
        options: {
          sort: {
            createdAt: -1,
          },
          query: {},
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
          ],
          balance: balanced,
        },
      },
    };
    const response = await axios.get("/customers", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getUnApprovedCustomers = createAsyncThunk(
  "customers/ge",
  async () => {
    let bodyContent = {
      params: {
        options: {
          query: {},
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
          ],
        },
      },
    };
    const response = await axios.get("/customers/unApproved", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getCustomerWithTransaction = createAsyncThunk(
  "customers/get",
  async ({ data, transaction }) => {
    let bodyContent = {
      params: {
        options: {
          query: {},
          populate: [
            {
              path: "customer",
              dir: "customers",
            },
          ],
          transaction: transaction,
        },
      },
    };
    const response = await axios.get(
      `/customers/transaction/${data}`,
      bodyContent
    );
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data.data || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getTransferListCustomers = createAsyncThunk(
  "customers/getAllData",
  async () => {
    let bodyContent = {
      params: {
        options: {
          query: {},
          limit: parseInt(100000),
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
          ],
          balance: "all",
        },
      },
    };
    const response = await axios.get("/customers", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk("customers/getData", async (params) => {
  const response = await axios.get("/customers", {
    params: {
      options: {
        ...params,
        populate: [
          {
            path: "createdBy",
            dir: "users",
            select: "username",
          },
        ],
        balance: params.balanced,
      },
    },
  });
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createCustomer = createAsyncThunk(
  "customers/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      dispatch(isLoading(true));
      var response = await axios.post("/customers", formdata);

      // dispatch(addCustomer(response.data?.data));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
    dispatch(isLoading(false));
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.patch(`/customers/${id}`, data);
      // await dispatch(getData(getState().users.params));

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      //  dispatch(editCustomer(response.data?.data));

      return response.data?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customers/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/customers/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      await dispatch(getAllCustomers());
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const customerSlice = createSlice({
  name: "customers",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    selectedCustomer: null,
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      state.error = null;
    },
    addCustomer: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectCustomer: (state, { payload }) => {
      state.selectedCustomer = payload;
    },
    editCustomer: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedCustomer = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllCustomers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getUnApprovedCustomers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUnApprovedCustomers.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUnApprovedCustomers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getCustomerWithTransaction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomerWithTransaction.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.loading = false;
        state.error = null;
        console.log("State :", state);
        console.log("action :", action.payload);
      })
      .addCase(getCustomerWithTransaction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(createCustomer.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedCustomer = null;
        // state.allData.unshift(payload.data);
        // state.data.unshift(payload.data);
      })
      .addCase(createCustomer.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedCustomer = null;
      })
      .addCase(updateCustomer.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedCustomer = null;
      })
      .addCase(updateCustomer.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      });
  },
});

export const { selectCustomer, searchFilter, isLoading } =
  customerSlice.actions;
export default customerSlice.reducer;
