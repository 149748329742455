// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios,{AxiosError} from "axios";

export const getAllExpenses = createAsyncThunk(
  "expenses/getAllData",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      let params = {
        params: {
          options: {
            query: {},
            populate: [
              {
                dir: "accountings/chartOfAccounts",
                path: "accountId",
              },
            ],
          },
        },
      };

      const response = await axios.get("accountings/expenses", params);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        const docs = response.data?.data?.docs || [];
        return { docs, totalRows: response.data?.data?.totalDocs };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(true));
  }
);

export const getData = createAsyncThunk(
  "expenses/getData",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      console.log(params);
      const response = await axios.get("accountings/expenses", {
        params: { options: params },
      });
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        return {
          params,
          data: response.data?.data?.docs,
        };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);
export const updateExpense = createAsyncThunk(
  "Expenses/update",
  async ({ formdata, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      var response = await axios.patch(`accountings/expenses/${id}`, formdata);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      dispatch(editExpense(response.data?.data));
      return { status: response.status, message: response.message };
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const createExpenses = createAsyncThunk(
  "Expenses/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      var response = await axios.post("accountings/expenses", formdata);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        dispatch(addExpense(response.data?.data));
        return { status: response.status, message: response.message };
      }
    } catch (error) {
      dispatch(isLoading(false));
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const deleteExpense = createAsyncThunk(
  "Expenses/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      const response = await axios.delete(`accountings/expenses/${id}`);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        await dispatch(getAllExpenses());
        return response.status;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const expenseSlice = createSlice({
  name: "expenses",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedExpense: null,
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      state.error = null;
    },
    addExpense: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectExpense: (state, { payload }) => {
      state.selectedExpense = payload;
    },
    editExpense: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExpenses.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});
export const { addExpense, selectExpense, editExpense, isLoading } =
  expenseSlice.actions;
export default expenseSlice.reducer;
