// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

// ** Async Thunks

// Fetch all configurations
export const getAllConfigurations = createAsyncThunk(
  "configurations/getAllData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/configurations");
      if (response.status !== 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Create or update a configuration
export const createOrUpdateConfiguration = createAsyncThunk(
  "configurations/createOrUpdate",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post("/configurations", formData);
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(response.data.message);
      }
      // Refresh the configurations list after creating/updating
      dispatch(getAllConfigurations());
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// ** Configuration Slice

export const configurationSlice = createSlice({
  name: "configurations",
  initialState: {
    data: [],
    loading: false,
    error: null,
    selectedConfiguration: null,
  },
  reducers: {
    selectConfiguration: (state, { payload }) => {
      state.selectedConfiguration = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllConfigurations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConfigurations.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllConfigurations.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createOrUpdateConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrUpdateConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createOrUpdateConfiguration.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// ** Export actions and reducer
export const { selectConfiguration } = configurationSlice.actions;
export default configurationSlice.reducer;
