// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllBooks = createAsyncThunk(
  "AccountingBooks/getAllData",
  async () => {
    let params = {
      params: {
        options: {
          query: {},
          populate: [],
        },
      },
    };

    const response = await axios.get("accountings/books", params);
    const docs = response.data?.data?.docs || [];
    return { docs, totalDocs: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk(
  "AccountingBooks/getData",
  async (params) => {
    const response = await axios.get("accountings/books", {
      params: { options: params },
    });
    return {
      params,
      data: response.data?.data?.docs,
    };
  }
);

export const updateBook = createAsyncThunk(
  "AccountingBooks/update",
  async ({ data, id }, { dispatch, getState,rejectWithValue }) => {
    try {
      var response = await axios.patch(`accountings/books/${id}`, data);
    } catch (error) {
    return  rejectWithValue(error)
    }
    
    // await dispatch(getData(getState().users.params));
    return response.data.data;
  }
);

export const createBook = createAsyncThunk(
  "AccountingBooks/create",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("accountings/books", data);
      // await dispatch(getAllBooks(getState().accountBook.params));
    } catch (error) {
     return rejectWithValue(error);
    }

    return response.data.book;
  }
);

export const deleteBook = createAsyncThunk(
  "AccountingBooks/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.delete(`accountings/books/${id}`);
      dispatch(getAllBooks());
    } catch (error) {
    return  rejectWithValue(error);
    }

    return response.status;
  }
);

export const AccountBookslice = createSlice({
  name: "accountingBooks",
  initialState: {
    data: [],
    total: 0,
    params: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBooks.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalDocs;
        state.params = action.payload.params;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
      })
      .addCase(createBook.fulfilled, (state, action) => {
        if (action.payload) {
           state.data = state.data.map((book)=>{
          book.isCurrent=false
          return book
        })
        state.data.push(action.payload);
        state.total += 1;
        }
       
      })
      .addCase(updateBook.fulfilled, (state, action) => {
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData
          .map((da) => {
            if (da._id == payload._id) {
              return payload;
            }
            return da;
          })
          .addCase(deleteBook.fulfilled, (state, action) => {
            state.data = state.data.filter(
              (book) => book._id != action.payload._id
            );
            state.allData = state.allData.filter(
              (book) => book._id != action.payload._id
            );
            state.total -= 1;
          });
      });
  },
});
export const {} = AccountBookslice.actions;
export default AccountBookslice.reducer;
