// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import authentication from "./authentication";
import users from "./users";
import buildings from "./building";
import CurrentBuilding from "./home";
import customers from "./customer";
import expenses from "./expenses";
import accounts from "./chartOfAccount";
import payments from "./payments";
import staff from "./staff";
import roles from "./roles";
import utilityType from "./utilityTypes";
import accountBook from "./accountBook";
import closes from "./pendingEntry";
import newEntry from "./newEntries";
import promises from "./promises";
import deposits from "./deposit";
import withdraws from "./withdraw";
import transfers from "./transfer";
import receipts from "./receipt";
import invoices from "./invoice";
import exchanges from "./exchange";
import configurations from "./configuration";

const rootReducer = {
  navbar,
  layout,
  authentication,
  users,
  buildings,
  expenses,
  accounts,
  payments,
  CurrentBuilding,
  customers,
  staff,
  roles,
  utilityType,
  accountBook,
  closes,
  newEntry,
  promises,
  deposits,
  withdraws,
  transfers,
  receipts,
  invoices,
  exchanges,
  configurations,
};

export default rootReducer;
