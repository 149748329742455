// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllBuildings = createAsyncThunk(
  "buildings/getAllData",
  async (appView, { rejectWithValue }) => {
    try {
      let bodyContent = {
        params: {
           query: {
            propertyStatus: appView? appView == 'salesAndBroker' ? ['For Sale','Broker Rent'] :['For Rent']:['For Rent'],
            },
          options: {
           
            // limit:15,
            populate: [
              {
                path: "category",
                dir: "categories",
                select: "name",
              },
            ],
          },
        },
      };
      console.log(bodyContent)
      const response = await axios.get("/buildings", bodyContent);
      const docs = response.data?.data?.docs || [];
      return { docs, totalRows: response.data?.data?.totalDocs };
    } catch (error) {
      console.log("Errrrrrror: ", error);
      return rejectWithValue(error);
    }
  }
);

export const getData = createAsyncThunk("buildings/getData", async (params) => {
  const response = await axios.get("/buildings", {
    params: { options: params },
  });
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createBuilding = createAsyncThunk(
  "buildings/create",
  async ({formdata,headers}, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post("/buildings", formdata,{headers:headers});
    // await dispatch(getData(getState().users.params));
    dispatch(addBuilding(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateFeaturedProperty = createAsyncThunk(
  "buildings/updateFeatured",
  async (data, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post(`/property/status/${data.id}`, data);
    // await dispatch(getData(getState().users.params));
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return { status: response.status, data: response.data.data };
  }
);

export const updateBuilding = createAsyncThunk(
  "buildings/update",
  async ({ formdata, id }, { dispatch, getState }) => {
    var response = await axios.patch(`/buildings/${id}`, formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(editBuilding(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteBuilding = createAsyncThunk(
  "buildings/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/buildings/${id}`);
    await dispatch(getAllBuildings());
    return response.status;
  }
);

export const buildingSlice = createSlice({
  name: "buildings",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedBuilding: null,
  },
  reducers: {
    
    addBuilding: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },

    selectBuilding: (state, { payload }) => {
      state.selectedBuilding = payload;
    },
    editBuilding: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedBuilding = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuildings.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getAllBuildings.rejected, (state, action) => {
        console.log(action.payload);
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })

      .addCase(updateFeaturedProperty.fulfilled, (state, { payload }) => {
        console.log(payload)
        state.data = state.data.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item
        });
        state.allData = state.allData.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item
        });
      });
  },
});

export const { addBuilding, selectBuilding, editBuilding } =
  buildingSlice.actions;
export default buildingSlice.reducer;
